import React from 'react';
// import { Link } from 'react-router-dom';


const styles = {
    cloudWebsite: {
      minHeight: '100vh',
      backgroundColor: '#e6f2ff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    heroSection: {
      position: 'relative',
      width: '100%',
      height: '384px',
      background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '23px',
    },
    window: {
    marginTop: '120px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cloudIcon: {
      color: '#3b82f6',
    },
    companyLogo: {
      position: 'absolute',
      top: '26%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '16px',
    },
    companyLogoImg: {
      width: '400px',
    },
    companyWindowImg: {
      width: '80%',
    },
    mottoSection: {
      textAlign: 'center',
      marginTop: '32px',
      padding: '0 16px',
    },
    mottoHeading: {
      fontSize: '1.80rem',
      fontWeight: 'bold',
      color: '#42210d',
      paddingTop: '20px',
    },
    mottoParagraph: {
      marginTop: '8px',
      fontSize: '1.125rem',
      color: '#342419',
      margin: '0 20%',
    },
    cardsSection: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '48px',
      padding: '0 16px',
    //   flexDirection: 'row',
    //   "@media (maxWidth: 700px)": {
    //     flexDirection: 'column',
    //   }
    },
    card: {
      backgroundImage: 'linear-gradient(to bottom, white, #c9e5f0)',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      width: '256px',
      margin: '20px 16px',
    },
    cardHeading: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#42210d',
    },
    cardParagraph: {
      marginTop: '8px',
      color: 'black',
    },
};


function Home() {
  return (
    <div style={styles.cloudWebsite}>
        <div style={styles.heroSection}>
            <div style={styles.companyLogo}>
                <img src="/wr.png" alt="Company Logo" style={styles.companyLogoImg} />
            </div>
            <div style={styles.window}>
                <img src="/openwin.jpeg" alt="Company Logo" style={styles.companyWindowImg} />
            </div>
            
        </div>

        <div style={styles.mottoSection}>
            <h1 style={styles.mottoHeading}>Comfort & Joy</h1>
            <p style={styles.mottoParagraph}>
              Experience the excitement of comfort and joy in the real estate industry. 
              Enjoy the fresh convenience of our addresses, designed to provide an unique living experience.
            </p>
        </div>

        <div style={styles.cardsSection} className='cardsec'>
            <div style={styles.card}>
                <h2 style={styles.cardHeading}>Listings</h2>
                <p style={styles.cardParagraph}>New (<i style={{color: '#d75a47'}}>Coming Soon</i>)</p>
            </div>

            <div style={styles.card}>
                <h2 style={styles.cardHeading}>Services</h2>
                <p style={styles.cardParagraph}>New (<i style={{color: '#d75a47'}}>Coming Soon</i>)</p>
            </div>
        </div>
    </div>
  );
}

export default Home;