import React from 'react';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div style={{ textAlign: "left", background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)' }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Link to="/">
                <img src="/wr.png" alt="Company Logo" style={{ width: "400px" }} />
            </Link>
            <h1>Contact Us</h1>
            {/* <p className="slogan">Innovation, Reimagined.</p> */}
            <hr />
            <p>Got a question, comment, or suggestion? We love to hear from you. Drop us a line anytime.</p>

            <p>You can contact us via email at:</p>

            <p><a href="mailto:info@walsonrealty.com">info@walsonrealty.com</a></p>

            <p>Thanks for visiting!</p>
        </div>
    </div>
  );
};

export default Contact;