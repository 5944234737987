import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';


const Privacy = () => {
  return (
    <div style={{ textAlign: "left", background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)' }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Link to="/">
                <img src="/wr.png" alt="Company Logo" style={{ width: "400px" }} />
            </Link>
            <h1>Privacy Policy</h1>
            <p className="slogan">Last Updated: 09/17/2024</p>
            <hr style={{marginTop: "50px", marginBottom: "50px"}} />
            <div id="privacy-policy-container">
                <p class="section-content">This Privacy Policy describes how <strong>Walson Realty, LLC </strong> ("we," "us," or "our") collects, uses, and shares personal information when you visit our website at <strong>https://www.walsonrealty.com</strong>. Please read this policy carefully to understand how we handle your information.</p>

                <h2 class="section-title">1. What Information Do We Collect?</h2>
                <p class="section-content">We collect limited information about visitors to our website in the following ways:</p>
                <ul class="privacy-list">
                    <li class="privacy-list-item"><strong>Visitor Analytics:</strong> We use Google Analytics to track basic information about visits to our website. This includes data such as:
                        <ul class="nested-list">
                            <li>IP addresses</li>
                            <li>Browser type</li>
                            <li>Pages visited</li>
                            <li>Time spent on the site</li>
                            <li>Referring URLs</li>
                        </ul>
                    </li>
                </ul>
                <p class="section-content">This data is collected anonymously and is only used for the purpose of understanding website traffic and improving user experience.</p>
                <ul class="privacy-list">
                    <li class="privacy-list-item"><strong>Contact Information:</strong> If you contact us via email (<strong>info@walsonrealty.com</strong>), we will collect your email address and any information you choose to provide in the email.</li>
                </ul>

                <h2 class="section-title">2. How Do We Use Your Information?</h2>
                <p class="section-content">We use the information collected through Google Analytics for the following purposes:</p>
                <ul class="privacy-list">
                    <li class="privacy-list-item">To monitor website performance and visitor behavior to improve our website.</li>
                    <li class="privacy-list-item">To diagnose any technical issues or security vulnerabilities.</li>
                </ul>
                <p class="section-content">We use your email address only to respond to inquiries if you contact us directly.</p>

                <h2 class="section-title">3. Cookies and Tracking Technologies</h2>
                <p class="section-content">We use cookies and similar tracking technologies via Google Analytics to understand how visitors interact with our website. These cookies do not store personally identifiable information. You can manage your cookie preferences through your browser settings.</p>

                <h2 class="section-title">4. Sharing Your Information</h2>
                <p class="section-content">We do not sell, trade, or share your personal data with third parties, except for the following cases:</p>
                <ul class="privacy-list">
                    <li class="privacy-list-item"><strong>Service Providers:</strong> We use Google Analytics, a third-party analytics provider, to collect visitor data. Their privacy policy can be viewed <a class="privacy-link" href="https://www.Google.com/privacy.html" rel="noreferrer" target="_blank">here</a>.</li>
                    <li class="privacy-list-item"><strong>Legal Requirements:</strong> We may disclose information if required to do so by law or if we believe that such action is necessary to comply with legal obligations.</li>
                </ul>

                <h2 class="section-title">5. Data Security</h2>
                <p class="section-content">We take reasonable steps to protect your personal data from unauthorized access or disclosure. While we strive to protect your personal information, please note that no method of transmission over the internet is 100% secure.</p>

                <h2 class="section-title">6. Your Data Protection Rights</h2>
                <p class="section-content">Under GDPR, you have the following rights:</p>
                <ul class="privacy-list">
                    <li class="privacy-list-item"><strong>Right to Access:</strong> You can request copies of your personal data.</li>
                    <li class="privacy-list-item"><strong>Right to Rectification:</strong> You can request corrections to inaccurate or incomplete data.</li>
                    <li class="privacy-list-item"><strong>Right to Erasure:</strong> You can request that we delete your personal data under certain conditions.</li>
                    <li class="privacy-list-item"><strong>Right to Restrict Processing:</strong> You can request that we restrict the processing of your data under certain conditions.</li>
                    <li class="privacy-list-item"><strong>Right to Object to Processing:</strong> You can object to our processing of your personal data.</li>
                    <li class="privacy-list-item"><strong>Right to Data Portability:</strong> You can request that we transfer your data to another organization or directly to you under certain conditions.</li>
                </ul>
                <p class="section-content">To exercise any of these rights, please contact us at <strong>services@reedwalson.com</strong>.</p>

                <h2 class="section-title">7. International Data Transfers</h2>
                <p class="section-content">Our website and the data collected through it are managed and stored on servers that may be located outside of your country of residence. We ensure that any international transfers comply with applicable data protection laws.</p>

                <h2 class="section-title">8. Changes to this Privacy Policy</h2>
                <p class="section-content">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date. We encourage you to review this policy periodically.</p>

                <h2 class="section-title">9. Contact Us</h2>
                <p class="section-content">If you have any questions or concerns about this Privacy Policy, please contact us at <strong>info@walsonrealty.com</strong>.</p>
            </div>
        </div>
    </div>
  );
};

export default Privacy;