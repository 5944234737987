import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row", textAlign: "left", background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)' }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Link to="/">
                <img src="/wr.png" alt="Company Logo" style={{ width: "400px" }} />
            </Link>
            <h1>About Us</h1>
            {/* <p style={{ fontStyle: "italic", marginBottom: "10px" }}>Innovation, Reimagined.</p> */}
            <hr style={{ border: "1px solid #ffffff", margin: "20px auto", width: "50%" }} />
            <div style={{ margin: "0 20%" }}>
                <p>At Walson Realty, LLC, we’re redefining the future of real estate by crafting spaces that blend luxury, innovation, and comfort. Our realty division is committed to curating living experiences that go beyond the ordinary, with up-and-coming services that promise to be nothing short of fabulous. From serene, stylish residences to thoughtfully integrated amenities, we are creating environments that inspire, relax, and elevate your everyday life.</p>

                <p>Our vision is to reimagine what modern living can be, offering not just homes, but a complete lifestyle experience. With beautifully designed spaces, cutting-edge technology, and future-forward innovations, we’re building more than just properties—we’re creating places where people truly want to live.  We believe in fulfilling desires and dreams, not just addressing needs.</p>

                <p>As we continue to grow, we are focused on leading with innovation, ensuring every service we offer is seamlessly integrated to deliver an exceptional, effortless experience. From tranquil retreats to vibrant urban spaces, Walson Realty is dedicated to reshaping the real estate landscape, offering unmatched living experiences that redefine comfort, excitement, and modern living.</p>
            </div>
        </div>
    </div>
  );
};

export default About;